import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import { Container } from "@material-ui/core"
import { Typography } from "@material-ui/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@material-ui/core/styles";
//Models
import { PromotionalTermModel } from "../../components/utils/model/PromotionalTermModel";
//Assets
import SANTA24 from "./../../assets/Benefits/SANTA_24.png";


const useStyles = makeStyles(theme => ({
    root: {},
    ol: { paddingInlineStart: 0 },
    containerPart: {
        [theme.breakpoints.down("md")]: {
            marginTop: "10em",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "7em",
        },
        marginTop: "12em",
    },
    imgSize: {
        [theme.breakpoints.down("xs")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xl")]: {
            width: "100%",
        },
        width: "100%",
    },
    boxImg: {
        display: "flex",
        justifyContent: "center",
        margin: "auto",
    },
    liStyle: {
        textAlign: "justify",
        padding: 3,
    },
    scrollableDiv: {
        [theme.breakpoints.down("xs")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("md")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("lg")]: {
            height: "700px", /* Alto del div */
        },
        [theme.breakpoints.down("xl")]: {
            height: "800px", /* Alto del div */
        },
        height: "auto", /* Alto del div */
        width: "auto", /* Ancho del div */
        overflow: "auto", /* Habilita scroll cuando el contenido excede las dimensiones */
        '&::-webkit-scrollbar': {
            width: '10px', // Ancho del scrollbar
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Color del track
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color del scrollbar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Color del scrollbar al hover
        },
    }
}));

export default function Benefits() {
    let propertiesLayout = new PromotionalTermModel("Santa24", SANTA24, "SANTA24");
    const classes = useStyles()
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{propertiesLayout.name.toUpperCase()} | Terminos & Condiciones</title>
            </Helmet>
            <Container className={classes.containerPart}>
                <Box mt={4} py={2}>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>Términos y Condiciones de la Campaña Promocional</b>
                    </Typography>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>“{propertiesLayout.name.toUpperCase()}”</b>
                    </Typography>
                </Box>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 6, md: 12 }} sx={{
                    maxHeight: {
                        xs: "auto", // Pantallas pequeñas
                        sm: "auto", // Pantallas medianas
                        md: "700px", // Pantallas grandes
                        lg: "800px", // Pantallas más grandes
                    }, marginBottom: 10
                }}>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        {" "}
                        <img
                            src={propertiesLayout.linkImage}
                            alt={propertiesLayout.desc}
                            className={classes.imgSize}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        <div className={classes.scrollableDiv}>
                            <Typography variant="h4" gutterBottom inline>
                                <b>1. Vigencia de la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                La campaña “SANTA24” estará vigente desde el 1 de diciembre de 2024 hasta el
                                31 de diciembre de 2024.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>2. Requisitos para participar</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para acceder a los beneficios de esta campaña, los clientes deben cumplir con los
                                siguientes requisitos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Clientes elegibles:</b> Clientes activos o con cuentas de reciente apertura
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Abono de capital:</b>  Realizar un abono de capital igual o superior a $10,000
                                        MXN en cualquiera de los siguientes productos de inversión “30CFL”,
                                        “90CFL”, “180CFL”, “UNO”, “Mercado” y “Dinámico” durante el periodo de
                                        vigencia de la campaña.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>3. Incentivo</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Los clientes que cumplan con los requisitos de la campaña podrán obtener los
                                siguientes beneficios:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Tasa de rendimiento:</b> Tasa anualizada máxima del 13% en cualquiera de
                                        los productos participantes, al realizar la contratación del producto
                                        seleccionado por un importe mínimo de $10,000 MXN.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Ventanas de liquidez:</b> En los productos “UNO”, “Mercado” y “Dinámico”,
                                        los clientes contarán con acceso a ventanas de liquidez periódicas.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>4. Ventanas de liquidez según producto:</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para tener acceso a la ventana de liquidez correspondiente a cada producto
                                deberá cumplirse un periodo de días necesarios como se describe a continuación:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ol>
                                    <li className={classes.liStyle}>
                                        <b>Producto UNO:</b> Ventana de liquidez cada 30 días.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Producto Mercado:</b> Ventana de liquidez cada 90 días.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Producto Dinámico: </b> Ventana de liquidez cada 180 días.
                                    </li>
                                </ol>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>5. Procedimiento para Acceder a la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para beneficiarse de esta campaña, los clientes deben seguir estos pasos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Paso 1:</b> Transferir fondos por un importe igual o superior a $10,000 MXN a
                                        su Cuenta Cero CFL.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 2:</b> Realizar la contratación del producto “30CFL”, “90CFL”, “180CFL”,
                                        “UNO”, “Mercado” o “Dinámico” por una importe igual o superior a $10,000
                                        MXN.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 3:</b> El cliente deberá solicitar a su Asesor Financiero la aplicación del
                                        beneficio de la presente campaña.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>6. Condiciones Generales</b>
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ol>
                                    <li className={classes.liStyle}>
                                        <b>Producto aplicable:</b> Esta promoción se aplica únicamente para los
                                        productos <b>“30CFL”, “90CFL”, “180CFL”, “UNO”, “Mercado” y “Dinámico”</b>.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Capital nuevo:</b> El capital debe ser nuevo y transferido entre el 1 de
                                        diciembre de 2024 y el 31 de diciembre de 2024.

                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Sin excepciones:</b> No se permitirán contrataciones fuera del plazo de
                                        vigencia.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Vigencia de la Tasa:</b> La tasa de rendimiento promocional está sujeta a
                                        modificaciones sin previo aviso, según condiciones de mercado.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Migración de fondos de otros productos:</b> En caso de querer migrar la
                                        totalidad o parte de una inversión previa a esta campaña, el cliente deberá
                                        incrementar el 20% sobre el monto que desea migrar.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Ventanas de Liquidez:</b> Al tratarse de productos de inversión a plazo fijo, el cliente tendrá el
                                        beneficio de poder acceder a sus recursos cada cierto tiempo, sin embargo,
                                        para realizarlo contará con un lapso de 7 días naturales posteriores una vez
                                        que se cumpla el número de días necesarios en cada producto.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Exclusividad de la Campaña:</b> Los fondos ingresados bajo esta promoción
                                        solo se aplican a los términos de esta campaña. Si el cliente desea
                                        participar en otra campaña, deberá cumplir con los términos específicos de
                                        esa campaña adicional.

                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Plazo de aplicación del beneficio:</b> El beneficio se reflejará en el producto
                                        seleccionado en un plazo máximo de 72 horas. Los clientes podrán
                                        confirmar el incremento en su tasa de interés a través de su aplicación
                                        móvil o sitio web.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Aceptación de los Términos y Condiciones:</b> Al participar en esta
                                        campaña, los clientes aceptan y se comprometen a cumplir con todos los
                                        términos y condiciones aquí establecidos.
                                    </li>

                                </ol>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Layout >
    )
}
